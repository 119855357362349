import * as siteGlobals from './imports/helper/globals.js';

import { addJsToHtml } from './imports/addJsToHtml.js';
import { detectTouch } from './imports/detectTouch.js';
import { changeHeader } from './imports/changeHeader.js';
import { tabs } from './imports/tabs.js';
import { swiper } from './imports/swiper.js';
import { vimeoEmbed } from './imports/vimeoEmbed.js';
import { offCanvas } from './imports/offCanvas.js';

addJsToHtml();
detectTouch();
changeHeader();
tabs();
swiper();
vimeoEmbed();
offCanvas();