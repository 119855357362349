import { body } from './helper/vars.js';

export function vimeoEmbed() {
	if (document.querySelector('button.m-card__wrap')) {
		const vimeoButton = document.querySelectorAll('button.m-card__wrap');

		if (document.querySelector('.l-page')) {
			createModal();
		}

		for (let i = 0; i < vimeoButton.length; i++) {
			// Extract video ID from data-embed-id
			const vimeoVideoId = extractVimeoId(vimeoButton[i].dataset.embedId);
			
			if (vimeoVideoId) {
				const vimeoPreview = vimeoButton[i].querySelector('.m-card__image');
				if (vimeoPreview) {
					vimeoPreview.src = "https://vumbnail.com/" + vimeoVideoId + ".jpg";
				}

				vimeoButton[i].addEventListener("click", (e) => {
					const vimeoModal = document.getElementById("m-content-overlay");
					const vimeoModalContent = document.getElementById("m-content-overlay__content");

					const embed = document.createElement("div");
					embed.className = "m-embed m-embed--16by9";
					const iframe = document.createElement("iframe");
					iframe.setAttribute("frameborder", "0");
					iframe.setAttribute("allow", "autoplay; fullscreen; picture-in-picture; clipboard-write");
					iframe.setAttribute("src", "https://player.vimeo.com/video/" + vimeoVideoId + "?title=0&byline=0&portrait=0&api=0");

					vimeoModalContent.innerHTML = "";
					embed.appendChild(iframe);
					vimeoModalContent.appendChild(embed);

					vimeoModal.classList.add('m-content-overlay--active');
					vimeoModal.setAttribute('aria-hidden', 'false');

					// Now that the modal is open, enable the Escape key listener
					enableEscapeKeyListener();
				});
			}
		}

		document.addEventListener('keydown', function(event) {
			if (event.key === 'Escape') {
				if (body.classList.contains('h-modal-is-expanded')) {
					removeModal();
				}
			}
		});

		function createModal() {
			const html = [
				'<div class="m-content-overlay__wrap">',
				'<button type="button" class="m-content-overlay__close js-close-modal" type="button">Modal schließen</button>',
				'<div id="m-content-overlay__content" class="m-content-overlay__content">',
				'</div>',
				'</div>'
			].join('');

			const modal = document.createElement('div');
			modal.id = "m-content-overlay";
			modal.className = 'm-content-overlay';
			modal.setAttribute("aria-hidden", "true");
			modal.innerHTML = html;
			body.appendChild(modal);

			const vimeoModalClose = modal.querySelector(".m-content-overlay__close");
			vimeoModalClose.addEventListener("click", function() {
				removeModal();
			});
		}

		function removeModal() {
			const vimeoModal = document.getElementById("m-content-overlay");
			const vimeoModalContent = document.getElementById("m-content-overlay__content");
			vimeoModal.setAttribute("modalClosing", "");

			vimeoModal.addEventListener("animationend", function() {
				vimeoModal.removeAttribute("modalClosing");
				vimeoModal.setAttribute('aria-hidden', 'true');
				vimeoModal.classList.remove('m-content-overlay--active');
				vimeoModalContent.innerHTML = "";

				// Disable Escape key listener when modal is closed
				disableEscapeKeyListener();
			}, {
				once: true
			});
		}

		// Enable Escape key listener only when the modal is open
		function enableEscapeKeyListener() {
			document.addEventListener('keydown', handleEscapeKey);
		}

		// Disable Escape key listener when modal is closed
		function disableEscapeKeyListener() {
			document.removeEventListener('keydown', handleEscapeKey);
		}

		// Escape key handler
		function handleEscapeKey(event) {
			if (event.key === 'Escape') {
				const vimeoModal = document.getElementById("m-content-overlay");
				if (vimeoModal && vimeoModal.classList.contains('m-content-overlay--active')) {
					removeModal();
				}
			}
		}

		/**
		 * Helper function to extract the Vimeo ID from a URL or return the ID if it's already provided.
		 * @param {string} value - The data-embed-id value (either a Vimeo URL or an ID).
		 * @returns {string|null} - The extracted Vimeo ID or null if invalid.
		 */
		function extractVimeoId(value) {
			const vimeoIdRegEx = /(?:https?:\/\/(?:player\.|www\.)?vimeo\.com\/(?:video\/|embed\/|watch\?[\S]*v=|v\/)?)(\d+)/;

			// Case 1: If the value is a valid Vimeo URL
			if (typeof value === 'string' && vimeoIdRegEx.test(value)) {
				const match = value.match(vimeoIdRegEx);
				return match ? match[1] : null;  // Extract and return the ID
			}

			// Case 2: If the value is just the Vimeo ID
			if (typeof value === 'string' && /^\d+$/.test(value)) {
				return value;  // It's already a Vimeo ID, return it
			}

			// Return null if the value is not a valid URL or ID
			return null;
		}
	}
}
