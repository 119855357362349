export function changeHeader() {
	const siteHeader = document.querySelector('.l-header');

	window.addEventListener('scroll', function() {
		const scrollpos = window.scrollY || document.documentElement.scrollTop;

		if (scrollpos > 60) {
			siteHeader.setAttribute("data-header", "sticky");
		} else {
			siteHeader.setAttribute("data-header", "scrolling");
		}
	});
}