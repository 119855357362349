import { pageBody } from './helper/vars.js';
export function detectTouch() {
	pageBody[0].addEventListener('mousedown', function() {
		if (this.classList.contains('h-using-keyboard')) {
			this.classList.remove('h-using-keyboard');
			this.classList.add('h-using-mouse');
		} else {
			this.classList.add('h-using-mouse');
		}
	});

	pageBody[0].addEventListener('keydown', function() {
		if (this.classList.contains('h-using-mouse')) {
			this.classList.remove('h-using-mouse');
			this.classList.add('h-using-keyboard');
		} else {
			this.classList.add('h-using-keyboard');
		}
	});
}