import { hideSubNav, removeButtonActiveState } from "./helper/helper.js";

export function offCanvas() {
	if (document.querySelector(".l-offcanvas")) {
		const pageBodyTag = document.querySelectorAll(".l-page");

		pageBodyTag.forEach((pageBodyTag) => {
			const mainWrapper = pageBodyTag.querySelectorAll(".l-main");
			const headerButton = pageBodyTag.querySelectorAll(
				"button[data-target]"
			);
			const offCanvasTrigger = pageBodyTag.querySelectorAll(
				".js-offcanvas-trigger"
			);
			const offCanvasCloseButton = pageBodyTag.querySelectorAll(
				".js-close-offcanvas"
			);
			const matchMedia = window.matchMedia("(max-width: 670px)");
			let overlayCreated = false;

			function addListenersToMatchMedia(mediaQuery, ...listeners) {
				listeners.forEach((listener) => {
					matchMedia.addListener(listener);
				});
			}

			function updateOverlayStatus() {
				if (matchMedia.matches) {
					overlayCreated = false; // Reset overlayCreated when media query matches
				}
			}

			function handleOverlayCreation() {
				if (!overlayCreated) {
					target.setAttribute("data-button-state", "active");
					createOverlay();
					overlayCreated = true;
				}
			}

			function handleOffCanvasClose() {
				hideSubNav();
				hideOffCanvas();
				removeButtonActiveState();
				removeOverlay();
				overlayCreated = false;
			}

			pageBodyTag.addEventListener(
				"click",
				function (event) {
					const target = event.target;
					const targetButton = target.getAttribute("data-target");

					if (target.classList.contains("js-offcanvas-trigger")) {
						if (!overlayCreated) {
							target.setAttribute("data-button-state", "active");
							createOverlay();
							overlayCreated = true;
						}

						showOffCanvas(target);
					}

					if (target.classList.contains("js-close-offcanvas")) {
						offCanvasCloseButton.forEach(handleOffCanvasClose);
					}
				},
				false
			);

			document.addEventListener("keydown", function (event) {
				if (
					event.key === "Escape" &&
					pageBodyTag.classList.contains("h-offcanvas-is-expanded")
				) {
					handleOffCanvasClose();
				}
			});

			function createOverlay() {
				const offCanvasCloseOverlay = document.createElement("div");
				offCanvasCloseOverlay.classList.add("l-main__overlay");
				offCanvasCloseOverlay.setAttribute(
					"offcanvasoverlayfadein",
					""
				);

				offCanvasCloseOverlay.addEventListener(
					"animationend",
					function () {
						offCanvasCloseOverlay.removeAttribute(
							"offcanvasoverlayfadein"
						);
					},
					{
						once: true,
					}
				);

				offCanvasCloseOverlay.addEventListener(
					"click",
					function (event) {
						handleOffCanvasClose();
					}
				);

				mainWrapper.forEach((mainWrapper) => {
					mainWrapper.appendChild(offCanvasCloseOverlay);
				});
			}

			function removeOverlay() {
				const offCanvasCloseOverlay =
					document.querySelectorAll(".l-main__overlay");

				offCanvasCloseOverlay.forEach((offCanvasCloseOverlay) => {
					offCanvasCloseOverlay.setAttribute(
						"offcanvasoverlayfadeout",
						""
					);
					offCanvasCloseOverlay.addEventListener(
						"animationend",
						function () {
							offCanvasCloseOverlay.removeAttribute(
								"offcanvasoverlayfadeout"
							);
							offCanvasCloseOverlay.remove();
						},
						{
							once: true,
						}
					);
				});
			}

			function showOffCanvas(element) {
				var modalTarget = "#" + element.getAttribute("data-target");
				var modalTargetWrap = document.querySelector(modalTarget);
				modalTargetWrap.setAttribute("role", "dialog");
				modalTargetWrap.setAttribute("aria-modal", "true");
				modalTargetWrap.removeAttribute("aria-hidden", "true");
				modalTargetWrap.closest(".l-page").classList.add("h-offcanvas-is-expanded");
				modalTargetWrap.closest(".l-page").classList.remove("h-offcanvas-is-collapsed");
			}

			function hideOffCanvas() {
				pageBodyTag.classList.remove("h-offcanvas-is-expanded");
				pageBodyTag.classList.add("h-offcanvas-is-collapsed");
				headerButton.forEach((headerButton) => {
					headerButton.removeAttribute("data-button-state", "active");
				});

				pageBodyTag
					.querySelectorAll(".l-offcanvas")
					.forEach((headerOverlay) => {
						if (
							headerOverlay.getAttribute("aria-modal") === "true"
						) {
							headerOverlay.setAttribute("offcanvasclosing", "");

							headerOverlay.addEventListener(
								"animationend",
								function () {
									headerOverlay.removeAttribute(
										"offcanvasclosing"
									);
									headerOverlay.removeAttribute(
										"role",
										"dialog"
									);
									headerOverlay.removeAttribute(
										"aria-modal",
										"true"
									);
									headerOverlay.setAttribute(
										"aria-hidden",
										"true"
									);
								},
								{
									once: true,
								}
							);
						}
					});
			}

			function cloneAndModifyNavigation() {
				const mainNavigation = document
					.getElementById("main-navigation")
					.cloneNode(true);
				mainNavigation.removeAttribute("id");
				mainNavigation.classList = "m-nav m-nav--offcanvas";

				const elementsToChange = mainNavigation.querySelectorAll(
					".m-nav__list, .m-nav__list-item, .m-nav__link, .m-nav--main"
				);

				elementsToChange.forEach((element) => {
					const classList = element.classList;
					classList.forEach((className) => {
						if (className.startsWith("m-nav--main")) {
							classList.remove(className);
							classList.add(
								className.replace(
									"m-nav--main",
									"m-nav--offcanvas"
								)
							);
						}
					});
				});

				const submenuItems = mainNavigation.querySelectorAll(
					"ul[data-level='two']"
				);

				submenuItems.forEach((submenu) => {
					const parentListItem = submenu.closest("li");
					parentListItem.classList.add("h-has-submenu");
					const parentLink = parentListItem.querySelector(
						"a[data-level='one']"
					);
					const wrapperDiv = document.createElement("div");
					wrapperDiv.classList =
						"m-nav__actions m-nav--offcanvas__actions";
					parentLink.insertAdjacentElement("afterend", wrapperDiv);

					const submenuWrapper = parentListItem.querySelector(
						".m-nav__actions.m-nav--offcanvas__actions"
					);
					submenuWrapper.appendChild(parentLink);

					const submenuButton = document.createElement("button");
					submenuButton.setAttribute("aria-expanded", "false");
					submenuButton.classList.add("c-subnav-toggle");
					submenuButton.textContent = "Toggle Submenu";
					submenuWrapper.appendChild(submenuButton);
					submenuButton.addEventListener("click", (event) => {
						submenuButton.setAttribute(
							"aria-expanded",
							submenuButton.getAttribute("aria-expanded") ===
								"true"
								? "false"
								: "true"
						);
						dropdownWrapper.classList.toggle("h-is-expanded");
						dropdownWrapper.classList.contains("h-is-expanded")
							? dropdownWrapper.setAttribute(
									"aria-hidden",
									"false"
							  )
							: dropdownWrapper.setAttribute(
									"aria-hidden",
									"true"
							  );
					});

					const dropdownWrapper = document.createElement("div");
					dropdownWrapper.classList.add("h-is-dropdown");
					dropdownWrapper.setAttribute("aria-hidden", "true");
					submenu.parentNode.insertBefore(dropdownWrapper, submenu);
					dropdownWrapper.appendChild(submenu);
				});

				const offcanvasBody = document.querySelector(
					"#offcanvas .l-offcanvas__row[data-section='menu']"
				);
				offcanvasBody.appendChild(mainNavigation);
			}

			cloneAndModifyNavigation();
			updateOverlayStatus();
			handleOffCanvasClose(matchMedia);

			addListenersToMatchMedia(
				matchMedia,
				updateOverlayStatus,
				hideOffCanvas,
				removeOverlay,
				removeButtonActiveState,
				hideSubNav
			);
		});
	}
}
