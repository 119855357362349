import {
	pageBody,
	regExp,
	googleApiKey
} from './vars.js';

export function hasClass(el, className) {
	return el.classList ? el.classList.contains(className) : new RegExp('\\b' + className + '\\b').test(el.className);
}

export function addClass(el, className) {
	if (el.classList) el.classList.add(className);
	else if (!hasClass(el, className)) el.className += ' ' + className;
}

export function removeClass(el, className) {
	if (el.classList) el.classList.remove(className);
	else el.className = el.className.replace(new RegExp('\\b' + className + '\\b', 'g'), '');
}

export function addObserver(el, options) {
	if (!('IntersectionObserver' in window) || !('IntersectionObserverEntry' in window) || !('intersectionRatio' in window.IntersectionObserverEntry.prototype)) {
		if (options.cb) {
			options.cb(el)
		} else {
			entry.target.classList.add('active')
		}
		return
	}

	let observer = new IntersectionObserver((entries, observer) => {
		entries.forEach(entry => {
			if (entry.isIntersecting) {
				if (options.cb) {
					options.cb(el)
				} else {
					entry.target.classList.add('active')
				}
				observer.unobserve(entry.target)
			}
		})
	}, options)
	observer.observe(el)
}

export function scrollTrigger(selector, options = {}) {
	let els = document.querySelectorAll(selector)
	els = Array.from(els)
	els.forEach(el => {
		addObserver(el, options)
	})
}

export function hideSubNav() {
	document.querySelectorAll(".m-nav--offcanvas__list-item .h-is-dropdown").forEach(function(subNavDropDown) {
		if (subNavDropDown.getAttribute('aria-hidden') === 'false') {
			subNavDropDown.classList.remove('h-is-expanded');
			subNavDropDown.setAttribute("aria-hidden", "true");
		}
	});
}

export function removeButtonActiveState() {
	document.querySelectorAll(".m-nav--offcanvas__list-item .c-subnav-toggle").forEach(function (subNavButton) {
		if (subNavButton.getAttribute('aria-expanded') === 'true') {
			subNavButton.setAttribute("aria-expanded", "false");
		}
	});
}

export function removeLocationHash() {
	const uri = window.location.toString();

	if (uri.indexOf("#") > 0) {
		var clean_uri = uri.substring(0, uri.indexOf("#"));

		window.history.replaceState({}, document.title, clean_uri);
	}
}

export function structuredVideoCnt(el) {
	Array.prototype.slice.call(el).forEach(function(youTubeItemProp) {
		var video_embed = youTubeItemProp.dataset.embed.match(regExp)[2];
		var url = "https://www.googleapis.com/youtube/v3/videos?part=contentDetails,snippet,statistics,player&id=" + video_embed + "&key=" + googleApiKey + "";
		var plainURL = "https://www.youtube.com/embed/" + video_embed + "";

		//var nameMetaTag = Array.prototype.slice.call(el.querySelector("meta[itemprop='name']"));
		//var descriptionMetaTag = Array.prototype.slice.call(el.querySelector("meta[itemprop='description']"));

		var embedURL = document.createElement('meta');
		embedURL.setAttribute('itemprop', 'embedURL');
		embedURL.setAttribute("content", plainURL);
		youTubeItemProp.appendChild(embedURL);

		if (window.fetch) {
			fetch(url).then(function(resp) {
				return resp.json();
			}).then(function(data) {
				var ytvideodata = data.items;
				return ytvideodata.map(function(rp) {

					var nameMetaTag = youTubeItemProp.querySelector("meta[itemprop='name']");
					var descriptionMetaTag = youTubeItemProp.querySelector("meta[itemprop='description']");

					if (!youTubeItemProp.contains(nameMetaTag)) {
						var title = document.createElement('meta');
						title.setAttribute('itemprop', 'name');
						title.setAttribute('content', rp.snippet.title);
						youTubeItemProp.appendChild(title);
					}

					if (!youTubeItemProp.contains(descriptionMetaTag)) {
						var description = document.createElement('meta');
						description.setAttribute('itemprop', 'description');
						description.setAttribute('content', rp.snippet.description);
						youTubeItemProp.appendChild(description);
					}

					var uploadedate = document.createElement('meta');
					uploadedate.setAttribute('itemprop', 'uploadDate');
					uploadedate.setAttribute('content', rp.snippet.publishedAt);

					var thumbnailUrl = document.createElement('meta');
					thumbnailUrl.setAttribute('itemprop', 'thumbnailUrl');
					thumbnailUrl.setAttribute('content', rp.snippet.thumbnails.high.url);

					var duration = document.createElement('meta');
					duration.setAttribute('itemprop', 'duration');
					duration.setAttribute('content', rp.contentDetails.duration);

					var viewCount = document.createElement('meta');
					viewCount.setAttribute('itemprop', 'interactionCount');
					viewCount.setAttribute('content', rp.statistics.viewCount);

					youTubeItemProp.appendChild(uploadedate);
					youTubeItemProp.appendChild(thumbnailUrl);
					youTubeItemProp.appendChild(duration);
					youTubeItemProp.appendChild(viewCount);
				});
			}).catch(function(error) {
				console.warn('YouTube API v3 fetch failed' ,error);
			});
		}
	});
}