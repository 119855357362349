export function swiper() {
	if (document.querySelector(".m-slider")) {
		const swiper = new Swiper('.swiper', {
			modules: [EffectCarousel],
			effect: 'carousel',
			carouselEffect: {
				opacityStep: 0.1,
				scaleStep: 0.2,

				// amount of side slides visible, can be 1, 2 or 3
				sideSlides: 3,
			},
			
			grabCursor: true,
			loop: true,
			loopAdditionalSlides: 1,
			slidesPerView: 'auto',
			
			navigation: {
				nextEl: '.m-slider__button-next',
				prevEl: '.m-slider__button-prev',
			},

			pagination: {
				el: '.swiper-pagination',
			},

			// autoplay: {
			// 	delay: 4500,
			// 	disableOnInteraction: true,
			// 	pauseOnMouseEnter: true,
			// },
		});
	}
}