export function tabs() {
	if (document.querySelector(".m-tabs")) {
		const tabsContainer = document.querySelector(".m-tabs");
		const tabsList = tabsContainer.querySelector(".m-tabs__list");
		const tabButtons = tabsList.querySelectorAll(".m-tabs__label");
		const tabPanels = tabsContainer.querySelectorAll(".m-tabs__panels > div");

		tabsList.setAttribute("role", "tablist");

		tabsList.querySelectorAll("li").forEach((listitem) => {
			listitem.setAttribute("role", "presentation");
		});

		tabButtons.forEach((tab, index) => {
			tab.setAttribute("role", "tab");
			if (index === 0) {
				tab.setAttribute("aria-selected", "true");
			} else {
				tab.setAttribute("tabindex", "-1");
				tabPanels[index].setAttribute("hidden", "");
			}
		});

		tabPanels.forEach((panel) => {
			panel.setAttribute("role", "tabpanel");
			panel.setAttribute("tabindex", "0");
		});

		tabsContainer.addEventListener("click", (e) => {
			const clickedTab = e.target.closest(".m-tabs__label");
			if (!clickedTab) return;
			e.preventDefault();

			switchTab(clickedTab);
		});

		tabsContainer.addEventListener("keydown", (e) => {
			switch (e.key) {
				case "ArrowLeft":
					moveLeft();
					break;
				case "ArrowRight":
					moveRight();
					break;
				case "Home":
					e.preventDefault();
					switchTab(tabButtons[0]);
					break;
				case "End":
					e.preventDefault();
					switchTab(tabButtons[tabButtons.length - 1]);
					break;
			}
		});

		function moveLeft() {
			const currentTab = document.activeElement;
			if (!currentTab.parentElement.previousElementSibling) {
				switchTab(tabButtons[tabButtons.length - 1]);
			} else {
				switchTab(
					currentTab.parentElement.previousElementSibling.querySelector(".m-tabs__label")
				);
			}
		}

		function moveRight() {
			const currentTab = document.activeElement;
			if (!currentTab.parentElement.nextElementSibling) {
				switchTab(tabButtons[0]);
			} else {
				switchTab(currentTab.parentElement.nextElementSibling.querySelector(".m-tabs__label"));
			}
		}

		function switchTab(newTab) {
			const activePanelId = newTab.getAttribute("href");
			const activePanel = tabsContainer.querySelector(activePanelId);

			tabButtons.forEach((button) => {
				button.setAttribute("aria-selected", false);
				button.setAttribute("tabindex", "-1");
			});

			tabPanels.forEach((panel) => {
				panel.setAttribute("hidden", true);
			});

			activePanel.removeAttribute("hidden", false);

			newTab.setAttribute("aria-selected", true);
			newTab.setAttribute("tabindex", "0");
			newTab.focus();
		}
	}
}